import React, { useState, useEffect } from 'react';
import IosShareIcon from '@mui/icons-material/IosShare';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import LaunchIcon from '@mui/icons-material/Launch';

const QuickAddToHomePage = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [installType, setInstallType] = useState(null);

    useEffect(() => {
        const detectInstallationType = () => {
            const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
            const isAndroid = /Android/i.test(navigator.userAgent);
            const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

            if (isStandalone) {
                setInstallType('already-installed');
            } else if (isIOS) {
                setInstallType('ios');
            } else if (isAndroid) {
                setInstallType('android');
            } else {
                setInstallType('desktop');
            }
        };

        detectInstallationType();
    }, []);

    const addToHomeScreen = () => {
        setShowAlert(true);
    };

    const renderInstallInstructions = () => {
        switch (installType) {
            case 'ios':
                return (
                    <Alert severity="info" style={{ direction: 'rtl' }}>
                        בשביל להוסיף את האפליקציה לחץ על כפתור השיתוף <IosShareIcon /> הנמצא למטה בדפדפן ואז "הוספה למסך הבית".
                    </Alert>
                );
            case 'android':
                return (
                    <Alert severity="info" style={{ direction: 'rtl' }}>
                        בשביל להוסיף את האפליקציה,היכנס דרך Chrome לחץ על שלוש הנקודות בפינה השמאלית העליונה של הדפדפן, ואז בחר "הוסף למסך הבית".
                    </Alert>
                );
            case 'desktop':
                return (
                    <Alert severity="info" style={{ direction: 'rtl' }}>
                        בשביל להוסיף את האפליקציה, לחץ על סמל ה-"התקן"<LaunchIcon/> בצד ימין של שורת הכתובת בדפדפן.
                    </Alert>
                );
            default:
                return null;
        }
    };

    if (installType === 'already-installed') {
        return ;
    }

    return (
        <div>
           

            <button style={{ marginTop: '50px', background:"burlywood",fontWeight:'700',fontSize:'smaller' }} onClick={addToHomeScreen}>
                הוסף את האפליקציה למסך הבית
            </button>
            {showAlert && (
                <Stack sx={{ width: '100%',marginTop: '50px'  }} spacing={2}>
                    {renderInstallInstructions()}
                </Stack>
            )}
        </div>
    );
};

export default QuickAddToHomePage;


 // const addToHomeScreen = () => {
    //     if (window.matchMedia('(display-mode: standalone)').matches) {
    //         alert('This app is already installed.');
    //         return;
    //     }

    //     let deferredPrompt;
    //     window.addEventListener('beforeinstallprompt', (e) => {
    //         e.preventDefault();
    //         deferredPrompt = e;
    //         if (deferredPrompt) {
    //             deferredPrompt.prompt();
    //             deferredPrompt.userChoice.then((choiceResult) => {
    //                 if (choiceResult.outcome === 'accepted') {
    //                     console.log('User accepted the A2HS prompt');
    //                 } else {
    //                     console.log('User dismissed the A2HS prompt');
    //                 }
    //                 deferredPrompt = null;
    //             });
    //         }
    //     });

    //     const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    //     if (isIOS) {
    //         setShowAlert(true)

    //     } else {
    //         // For Android, show an alert if the prompt is not available
    //         if (!deferredPrompt) {
    //             setShowAlert2(true)

    //         }
    //     }
    // };