import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});



export default function UploadFile({ onChange,fileInputRef}) {
  const handleFileChange = (e) => {
    if (onChange) {
      onChange(e); // Call the onChange prop with the event object
    }
  };

  const [isFlashing, setIsFlashing] = useState(true);

useEffect(() => {
  const intervalId = setInterval(() => {
    setIsFlashing(!isFlashing);
  }, 3000); // Adjust the flashing interval as needed

  return () => clearInterval(intervalId);
}, []);

  return (
    <Button style={{marginTop:'10px',marginBottom:'10px',fontSize:'23px',fontFamily: '"Secular One", sans-serif'}}
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      className={` ${isFlashing ? 'flashing' : ''}`}
    >
      העלה תצלום מסך
      <VisuallyHiddenInput type="file" accept="image/*" onChange={handleFileChange} ref={fileInputRef} />
       {/* Pass handleFileChange to the onChange event */}
    </Button>
  );
}
