// import React from 'react';
// import { createRoot } from 'react-dom/client';
// import { Auth0Provider } from '@auth0/auth0-react';
// import App from './App';
// import { RequestProvider } from './components/RequestContext';


// const root = createRoot(document.getElementById('root'));

// root.render(
//   <Auth0Provider
//     // domain={process.env.REACT_APP_AUTO_0_DOMAIN_LOCAL}
//     // clientId={process.env.REACT_APP_AUTO_0_CLIENTID_LOCAL}
//     domain={process.env.REACT_APP_AUTO_0_DOMAIN_PRODUCTION}
//     clientId={process.env.REACT_APP_AUTO_0_CLIENTID_PRODUCTION}
//     authorizationParams={{
//       redirect_uri: window.location.origin + "/Main",
//     }}
//   >
//   <RequestProvider>
//       <App />
//     </RequestProvider>
//   </Auth0Provider>
  
// );


import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { RequestProvider } from './components/RequestContext';

const Auth0ProviderWithHistory = ({ children }) => {
    // const domain=process.env.REACT_APP_AUTO_0_DOMAIN_LOCAL
    // const clientId=process.env.REACT_APP_AUTO_0_CLIENTID_LOCAL
    
  const domain = process.env.REACT_APP_AUTO_0_DOMAIN_PRODUCTION;
  const clientId = process.env.REACT_APP_AUTO_0_CLIENTID_PRODUCTION;

  const onRedirectCallback = (appState) => {
    window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin + "/Main"}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(
  <Auth0ProviderWithHistory>
    <RequestProvider>
      <App />
    </RequestProvider>
  </Auth0ProviderWithHistory>
);
