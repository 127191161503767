import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Screenshot from './screenshot.png'
import Send from './send.png'
import LoadingButtonsTransition from './Loader';
import DiscreteSliderValues from './Slider'
import DraggingFingerAnimation from './PalmDragging';
import { useNavigate } from "react-router-dom";

const steps = [
    {
        label: 'שלב ראשון העלאת תמונה',
        description: `• זה קל! העלה תמונה של השיחה.
\n
        • חפש את כפתור "העלה תמונה" .
\n
        • בחר את צילום המסך ותוכל להמשיך.`,
    },
    {
        label: 'שליחת הבקשה',
        description:
            `• לחץ על "שלח": לאחר שהעלת את התמונה, חפש את כפתור ה"שלח" ולחץ עליו
                    \n
                    \n
        • המתן לתשובה: אנחנו נעבוד על הבקשה שלך ונשלח לך תשובה בהקדם.`,
    },
    {
        label: 'תן קצת תיבול לתגובה שלך!',
        description: `אחרי שקיבלת את התשובה הראשונית, אתה יכול להתאים אותה לסגנון שאתה רוצה!
איך עושים את זה?

בחר את הטון: תוכל לבחור את הטון הרצוי לתגובה הבאה שלך. לדוגמה:`,
    },
];

export default function TextMobileStepper() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;
    const [loading, setLoading] = useState(false);
    const [zoomEffect, setZoomEffect] = useState(false);
    const [zoomEffect1, setZoomEffect1] = useState(false);
    const navigate = useNavigate();
    const [responseLabel, setResponseLabel] = useState('תגובה לדוגמא');

    const handleSliderChange = () => {
        setZoomEffect(true);
        const targetButton = document.querySelector('.scrolldownbtn');
        if (targetButton) {
            targetButton.scrollIntoView({
                behavior: 'smooth', // Smooth scrolling
                block: 'end', // Scroll to the top of the element
            });
        }
        setTimeout(() => {
            setZoomEffect(false);
        }, 1000);
    };

    const navigatetoMain = () => {
        navigate("/FirstTime");

    }



    const generatedResponse = () => {
        setLoading(true);
        setZoomEffect1(true);

        setTimeout(() => {

            setLoading(false);
            setZoomEffect1(false);
            setResponseLabel('תגובה נוספת');


        }, 1500); // 1.5 seconds delay

    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{ maxWidth: 400, flexGrow: 1, direction: 'rtl' }} >
            <h3> אז איך זה עובד?</h3>
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: '#00b9ff',
                    borderRadius: '10px'

                }}
            >

                <Typography style={{ padding: '20px' }}>{steps[activeStep].label}</Typography>

            </Paper>

            <Box sx={{ height: 130, maxWidth: 400, width: '100%', p: 2, direction: 'rtl' }}>
                {steps[activeStep].description}
            </Box>
            {activeStep === 0 && (

                <div style={{ height: '200px', whiteSpace: 'pre-line' }}>


                    <Box sx={{ mb: 2 }} >
                        כפתור להמחשה
                        <img src={Screenshot} alt="Screenshot" style={{ width: '100%' }} onClick={handleNext} />
                    </Box>
                </div>
            )}
            {activeStep === 1 && (
                <div style={{ height: '200px', whiteSpace: 'pre-line' }}>

                    <Box sx={{ mb: 2, whiteSpace: 'pre-line' }}>
                        כפתור להמחשה
                        <img src={Send} alt="Send" style={{ width: '30%', borderRadius: '20px' }} onClick={handleNext} />
                    </Box>
                </div>

            )}
            {activeStep === 2 && (
                <div style={{
                    marginTop: '20px',
                    transform: zoomEffect1 ? 'scale(1.4)' : 'scale(1)',
                    transition: 'transform 0.9s ease-in-out',
                }}>
                    <Button className='scrolldownbtn' onClick={navigatetoMain} variant="contained" >כנס עכשיו</Button>
                </div>
            )}
            {activeStep === 2 && (
                <Box sx={{ mb: 2 }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        direction: 'ltr',
                    }}>
                        <DraggingFingerAnimation />
                        <DiscreteSliderValues onChange={handleSliderChange} />
                        <h1 style={{ marginTop: '10px' }}>
                            תבחר לך מצב רוח
                        </h1>
                        <div className="response-container" style={{ marginTop: '20px' }}>
                            <div style={{ direction: "rtl", fontWeight: 600 }}>
                                {responseLabel}
                            </div>
                            <div style={{ fontSize: 'small', marginTop: '20px', color: 'gray' }}>
                                זוהי רק הצעה
                            </div>
                        </div>

                        <LoadingButtonsTransition
                            onClick={generatedResponse}
                            loading={loading}
                            buttonLabel="תגובה חדשה"
                            style={{
                                transform: zoomEffect ? 'scale(1.4)' : 'scale(1)',
                                transition: 'transform 0.9s ease-in-out',
                            }}
                        />
                    </div>

                </Box>
            )}

            <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        הבא
                        {theme.direction === 'ltr' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'ltr' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        חזור
                    </Button>
                }
            />

        </Box>
    );
}
