import React from 'react'

function Forum() {
  return (
    <div className="custom-home-page">
      <div className="hero">
        <div className="circle">

        </div>
        <div className="cool-move">

        </div>
        <div className="login-move">
          <p style={{direction:'rtl'}}>
            אנחנו עובדים על זה בקרוב יהיה מוכן...
            </p></div>
      </div>
    </div>
  )
}

export default Forum