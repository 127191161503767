import React, { useEffect, useState } from 'react';
import Spicy from '../spicy2.jpeg';

const DraggingFingerAnimation = () => {
  const [position, setPosition] = useState(0);
  const [opacity, setOpacity] = useState(1); // Add opacity state

  useEffect(() => {
    const animationDuration = 3000; // 3 seconds total
    const framesPerSecond = 60;
    const totalFrames = (animationDuration / 1000) * framesPerSecond;

    let frame = 0;
    const intervalId = setInterval(() => {
      if (frame >= totalFrames) {
        clearInterval(intervalId);
        
        // Trigger fade-out when the animation ends
        setTimeout(() => {
          setOpacity(0); // Start fading out
        }, 0); // Start immediately after the animation ends
        return;
      }

      const progress = frame / totalFrames;
      const easedProgress = easeInOutQuad(progress); // Update easing function for smoother start
      setPosition(easedProgress * 100);

      frame++;
    }, 1000 / framesPerSecond);

    return () => clearInterval(intervalId);
  }, []);

  // Updated easing function for smoother start and end (ease-in-out)
  const easeInOutQuad = (t) => (t < 0.2 ? 2 * t * t : -1 + (4 - 2 * t) * t);

  return (
    <div className="relative w-full h-32 bg-blue-100" style={{ zIndex: '12' }}>
      <div
        className="absolute top-1/2 transform -translate-y-1/2 transition-all duration-100 ease-linear"
        style={{ left: `${position}%`, opacity: opacity, transition: 'opacity 2s ease' }} // Add opacity transition
      >
        <span
          className="text-6xl select-none inline-block transform rotate-353"
          role="img"
          aria-label="Pointing finger"
        >
          <img style={{ borderRadius: '35px', marginBottom: '15px' }} src={Spicy} />
        </span>
      </div>
      <div
        className="absolute bottom-2 left-2 h-2 bg-blue-500 rounded-full transition-all duration-100 ease-linear"
        style={{ width: `calc(${position}% - 16px)`, maxWidth: 'calc(100% - 16px)' }}
      />
    </div>
  );
};

export default DraggingFingerAnimation;
