import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExampleImage from '../../ExampleImage.jpeg';
import ExampleVideo from '../Example-video.mp4'

export default function ExampleDialog({ handleCancel }) {
    const [open, setOpen] = useState(true); // Initialize open state


    const handleClose = () => {
        setOpen(false); // Close the dialog
        handleCancel(); // Call the handleCancel function passed as a prop
    };



    return (
        <>
            <Dialog open={open} onClose={handleClose} >
                {/* <DialogTitle style={{direction:'rtl'}}>ראה שיחה לדוגמא</DialogTitle> */}
                <DialogContent>




                    <DialogContentText>
                        {/* <h1 style={{ direction: 'rtl' }}>ראה שיחה לדוגמא</h1> */}
                        {/* <img src={ExampleImage} style={{ width: '80%', marginLeft: "10%" }} alt="Example Image" /> */}
                        {/* <b> */}
                            {/* <div style={{ direction: 'rtl' }}> */}
                                {/* ההמלצות שלנו: */}
                                {/* <ul style={{ direction: 'rtl' }}> */}
                                    {/* <li>השיחה תיגמר במשפט מהבחורה</li> */}
                                    {/* <li>לא לחתוך יותר מדי את התמונה.</li> */}
                                    {/* <li>שיהיה לפחות 5 משפטים.</li> */}
                                    {/* הוסף כאן פריטים נוספים לרשימה, אם תרצי */}
                                {/* </ul> */}
                            {/* </div> */}
                        {/* </b> */}

                        <video
                            style={{ width: '80%', marginLeft: "10%" }}
                            controls
                            src={ExampleVideo}
                            alt="Example Video">
                            Your browser does not support the video tag.
                        </video>
                        <Button onClick={handleClose} className='log-button button0 button2'>

                            סגור
                        </Button>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </>
    );
}
