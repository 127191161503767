import React, { useState, useContext, useEffect } from "react";
import SwitchMui from './SwitchMui'
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios'; // Import Axios
import { RequestContext } from "./RequestContext";
import brokenHeart from '../brokenheart.png';
import { Button } from "@mui/material";
import DialogModal from "./Dialogs/DialogLogin"; // Import the DialogModal component
import SyncIcon from '@mui/icons-material/Sync';

function Settings() {
    const [selectedSide, setSelectedSide] = useState(true);
    const { isAuthenticated, user } = useAuth0();
    const [termsAccepted, setTermsAccepted] = useState(true);
    const navigate = useNavigate();
    const [showAuthDialog, setShowAuthDialog] = useState(false); // State variable to manage whether the auth dialog is open

    const {
        requestLeft,

        daysRemaining,
        hoursRemaining,

    } = useContext(RequestContext);



    // Additional check to ensure user object is defined before rendering
    useEffect(() => {
        if (!user) {
            navigate('/Main');
        }
    }, [user, navigate]);


    const handleSwitchChange = (event) => {
        const newValue = event.target.checked;
        setSelectedSide(newValue);
        console.log("Switch value:", newValue ? 'ימין' : 'שמאל');
    };


    const sendToServer = () => {
        if (!isAuthenticated) {
            console.error("User not authenticated");
            setShowAuthDialog(true);
            return;
        }

        const userEmail = user.email; // Access the user's email from the user object

        axios.post('https://web-production-dd6e3.up.railway.app/date/check-hebrew', { termsAccepted: true, selectedSide, email: userEmail })
            .then(response => {
                console.log(response.data, 'success');
                navigate("/");
                // Handle successful response if needed
            })
            .catch(error => {
                console.error(error.response.data, 'not success');
                // Handle error if needed
            });
    };



    const handleDeleteCache = async () => {
        try {
            await axios.post('https://web-production-dd6e3.up.railway.app/date/delete-cache');
            console.log('Cache cleared successfully.');
        } catch (error) {
            console.error('Error clearing cache:', error);
        }
    };

    if (!isAuthenticated || !user) {
        return null; // Optionally, you can return a loading indicator or a message here
    }
    return (
        <div className="custom-home-page">
            <div className="hero" >
                <div className="cool-move">



                    {showAuthDialog && (
                        <DialogModal
                            handleConfirm={() => setShowAuthDialog(false)}
                            handleCancel={() => setShowAuthDialog(false)}
                        />
                    )}
                    <div style={{ direction: "rtl" }}>
                        <p>שלום {user.name}</p>
                    </div>
                    שנה צד הודעות
                    <SwitchMui handleSwitchChange={handleSwitchChange}></SwitchMui>

                    {termsAccepted && (
                        <div className="btn-login">
                            <button
                                className='log-button button0 button2'
                                onClick={() => {
                                    sendToServer();
                                }}
                            >
                                שלח
                            </button>
                        </div>

                    )}

                    {(daysRemaining < 0 && requestLeft <= 0 && isAuthenticated) ? (
                        <Link to='/PurchaseToken'>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginTop: '30px', fontSize: 'large' }}
                            >
                                <img src={brokenHeart} style={{ boxShadow: 'none', marginRight: '10px' }} alt="brokenHeart" />
                                החבילה נגמרה
                                <br />
                                לחץ כדי לקנות
                            </Button>
                        </Link>
                    ) : (
                        <div style={{ marginTop: '20px', border: '1px solid #ccc', borderRadius: '10px' }}>
                            <p>
                                נשארו לך עוד {daysRemaining} ימים ו {hoursRemaining} שעות עד לסיום החבילה
                            </p>
                        </div>
                    )}
                    {(user.email === 'moshiktm1994@gmail.com' || user.email === 'guystez@gmail.com') && (
                        <button style={{ marginTop: '20px' }} onClick={handleDeleteCache}>
                            <SyncIcon /> רענון שרת
                        </button>
                    )}
                </div>

            </div>
        </div>
    )
}

export default Settings