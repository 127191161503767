import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const ThanksPage = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const { isAuthenticated, user } = useAuth0();
  const [showComponent, setShowComponent] = useState(false);

  const query = useQuery();
  const status = query.get('status');
  const orderReference = query.get('order_reference');
  const uniqId = query.get('uniqId');
  const statusCode = query.get('statusCode');
  const token = query.get('token');
  const last4Digits = query.get('Last4Digits');
  const tokenExpirationMonth = query.get('TokenExpirationMonth');
  const tokenExpirationYear = query.get('TokenExpirationYear');
  const orderNumber = query.get('ordernumber');

  useEffect(() => {
    const sendStatusToBackend = async () => {
      // const uniqueKey = query.get('key');
      if (status !== 'success') {
        alert('Payment was not successful. Please try again.');
        return; // Stop further execution if payment status is not success
      }

      if (isAuthenticated) {
        const selectedDuration = localStorage.getItem(`user_duration${user.email}`);
        // const response2 =await axios.get(`https://api.takbull.co.ilapi/ExtranalAPI/GetTransaction?transactionInternalNumber=${orderNumber}`)
        // console.log(response2.data)
        try {
          const response = await axios.post('https://web-production-dd6e3.up.railway.app/date/purchase-token', {
            status,
            orderReference,
            uniqId,
            statusCode,
            token,
            last4Digits,
            tokenExpirationMonth,
            tokenExpirationYear,
            orderNumber,
            email: user.email,
            duration: selectedDuration
          });

          console.log('Status sent to backend:', response.data);
          console.log(orderNumber,'@@@@@@@@@@@');
          if (response.data.status = "Token purchased successfully") {
            setShowComponent(true)
            localStorage.removeItem(`user_duration${user.email}`)
          }
          else{
            alert('Payment was not successful. Please try again.')
          }
        } catch (error) {
          console.error('Error sending status to backend:', error);
        }
      }
    };

    if (status) {
      sendStatusToBackend();
    }
  }, [status, orderReference, uniqId, statusCode, token, last4Digits, tokenExpirationMonth, tokenExpirationYear, orderNumber, isAuthenticated]);



  return (
    <div className="custom-home-page">
      <div className="hero" style={{ direction: 'rtl' }}>
        <div className="circle"></div>
        <div className="cool-move">
          {showComponent && (
            <div>
              <h1>התשלום עבר בהצלחה!</h1>
            <p>בדקות הקרובות יישלח אליך מייל עם הקבלה על הרכישה.</p>
            <button 
            className="cool-move button" 
            onClick={() => window.location.href = '/'}
            style={{
              backgroundColor: '#6200ea', // Purple color
              color: 'white',
              padding: '20px 40px',
              border: 'none',
              borderRadius: '15px',
              fontSize: '18px',
              cursor: 'pointer',
              transition: 'background-color 0.3s, transform 0.3s',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              display: 'inline-block',
              margin: '20px 0',
              textAlign: 'center',
              fontWeight: 'bold'
            }}
          >
           <Link to="/Main"> חזרה לעמוד  הבית </Link>
                     </button>
            <div className="checkmark">
              <svg 
                version="1.1" 
                id="Layer_1" 
                x="0px" 
                y="0px"
                viewBox="0 0 161.2 161.2" 
                enableBackground="new 0 0 161.2 161.2"
                xmlSpace="preserve"
              >
                <path className="path" fill="none" stroke="#FFFFFF" strokeMiterlimit="10" d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
                  c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
                  c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"/>
                <circle className="path" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"/>
                <polyline className="path" fill="none" stroke="#FFFFFF" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 
                  74.1,108.4 48.2,86.4 "/>
                <circle className="spin" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.2175,12.2175" cx="80.6" cy="80.6" r="73.9"/>
              </svg>

            </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThanksPage;
